<template>
  <v-card class="segmented mb-4" color="secondary" flat rounded>
    <v-card-title>
      <v-row>
        <v-col cols="12">
          <h4>
            {{ $t(`feedbacks.processTypes_long.${feedbackData.processType}`) }}
          </h4>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <table>
        <tr>
          <td class="pr-8">{{ $t('feedbacks.type') }}</td>
          <td>
            <strong>{{ feedbackTitle }}</strong>
          </td>
        </tr>
        <tr v-if="!isAnonymous">
          <td class="pr-8">
            {{ $t('feedbacks.recipient') }}
          </td>
          <td>
            <strong>{{ feedbackData.recipientName }}</strong>
          </td>
        </tr>
        <tr v-if="!isAnonymous">
          <td class="pr-8">
            {{ $t('feedbacks.talkDate') }}
          </td>
          <td>
            <strong v-if="feedbackData.talkDate">{{
              $d(new Date(feedbackData.talkDate), 'datetime')
            }}</strong>
          </td>
        </tr>
        <tr>
          <td class="pr-8">
            {{ $t('employeeDetails.position') }}
          </td>
          <td>
            <strong>{{ localize(feedbackData.recipientPosition) }}</strong>
          </td>
        </tr>
        <tr v-if="!isAnonymous">
          <td class="pr-8">
            {{ $t('feedbacks.donor') }}
          </td>
          <td>
            <strong>{{ feedbackData.donorName }}</strong>
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    feedbackData: {
      type: Object,
      required: true
    },

    isAnonymous: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    feedbackTitle() {
      if (this.feedbackData.title?.length > 0 ?? false) {
        return this.localize(this.feedbackData.title);
      }
      return this.$t(`feedbacks.typeOptions.${this.feedbackData.feedbackType}`);
    }
  }
};
</script>
