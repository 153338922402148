<template>
  <div style="width: 29cm" class="print-view">
    <feedback-head
      v-if="!loading"
      :feedbackData="feedbackData"
      :isAnonymous="isAnonymous"
    ></feedback-head>

    <v-skeleton-loader
      v-if="loading"
      type="paragraph@3, text@6, paragraph@2, actions"
    ></v-skeleton-loader>

    <questionnaire
      v-else
      :form="feedbackData.formContent"
      :targets="feedbackData.targetItems"
      :readOnly="!feedbackData.canWrite || feedbackData.isSubmitted"
      :loading="loading"
      :isAnonymous="isAnonymous"
      printView
      @submit="submit"
    ></questionnaire>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import feedbackHead from '@/components/feedbacks/feedback-head.vue';
import questionnaire from '@/components/forms/questionnaire/questionnaire.vue';

export default {
  components: {
    questionnaire,
    feedbackHead
  },

  data: () => ({
    feedbackData: null,
    loading: true
  }),

  async mounted() {
    await this.loadCustomSettings('hays');

    const feedbackId = this.$route.params.id;

    if (this.isAnonymous) {
      const data = await this.loadAnonymousFeedback({
        feedbackId: feedbackId,
        accessToken: this.$route.params.accessToken
      });

      this.feedbackData = data.result;
    } else {
      const data = await this.loadFeedback(feedbackId);
      this.feedbackData = data.result;
    }

    this.loading = false;

    if (!this.hasDevelopmentComponent) {
      this.print();
    }
  },

  watch: {
    developmentComponentLoaded(val) {
      val && this.print();
    }
  },

  computed: {
    ...mapGetters({
      developmentComponentLoaded: 'careerDevelopment/getComponentLoaded'
    }),

    hasDevelopmentComponent() {
      if (this.isAnonymous) {
        return false;
      }

      return this.feedbackData?.formContent?.pages?.some((page) =>
        page.elements?.some((elem) => elem.customComponent === 'development')
      );
    },

    isAnonymous() {
      return (
        this.$route.params.accessToken !== null &&
        this.$route.params.accessToken !== undefined
      );
    }
  },

  methods: {
    ...mapActions({
      loadAnonymousFeedback: 'feedbacks/loadAnonymousFeedback',
      loadFeedback: 'feedbacks/loadFeedback',
      loadCustomSettings: 'forms/loadCustomSettings',
      submitFeedback: 'feedbacks/submitFeedback'
    }),

    submit() {
      this.submitFeedback(this.$route.params.id);
    },

    print() {
      setTimeout(() => window.print(), 1000);
    }
  }
};
</script>

<style>
.print-view .v-btn {
  display: none;
}

.print-view .scale-wrapper .v-btn {
  display: inline-flex;
}
</style>
